<!-- 預約查詢結果 -->
<template>
  <div class="card-reservation">
    <div class="card-reservation__avatar">
        <img v-bind:src="photo" alt="">
    </div>
    <div class="card-reservation__info">
      <div class="card-reservation__row">
        <span class="card-reservation__name">{{
          reservation.patientName
        }}</span>
        <span class="card-reservation__course-number">{{
          reservation.patientPhoneLast4Digits
        }}</span>
      </div>
      <div class="card-reservation__row">
        <span class="card-reservation__course-name">{{
          reservation.treatmentName
        }}</span>
      </div>
    </div>
    <div class="card-reservation__detail">
      <div class="card-reservation__row">
        <span class="card-reservation__date">預約日期：{{ reservation.scheduleDate.replaceAll("-", ".") }}</span>
        <span class="identity">    {{reservation.treatmentStaffName}}</span>
      </div>
      <div class="card-reservation__row">
        <span class="card-reservation__time"
          >預約時間：{{ reservation.scheduleTime }}</span
        >
        <span :class="serviceStaffColorClass(reservation.serviceStaffJob)">{{reservation.serviceStaffName}}</span>
        <button class="card-btn-menu"  :class="{ 'is-active':isCardMenuActive }" @click="toggleCardMenu"></button>
        <div class="card-option" :class="{ 'is-active':isCardMenuActive }">
          <button class="card-option__btn" @click="setInfusion">點滴設定</button>
          <button class="card-option__btn" @click="onCancel">取消</button>
          <button class="card-option__btn" @click="onReschedule">改期</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { serviceStaffColorClass } from '../utils/utils'
import { photoUrl } from '@/api/v1/reservations'

export default {
  name: 'CardReservation',
  props: ['reservation'],
  emits: ['checkin', 'cancel', 'reschedule', 'sms', 'set-infusion'],
  data () {
    return {
      isCardMenuActive: false
    }
  },
  computed: {
    photo () {
      if (this.reservation.patientPhoto) {
        return photoUrl('patients', this.reservation.patientId)
      } else {
        // use default profile head icon
        return (this.reservation.patientGender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
      }
    }
  },
  methods: {
    serviceStaffColorClass (jobName) {
      return serviceStaffColorClass(jobName)
    },
    toggleCardMenu () {
      this.isCardMenuActive = !this.isCardMenuActive
    },
    onCheckin () {
      this.$emit('checkin', this.reservation)
    },
    onCancel () {
      this.isCardMenuActive = false
      this.$emit('cancel', this.reservation)
    },
    onReschedule () {
      this.isCardMenuActive = false
      this.$emit('reschedule', this.reservation)
    },
    onSMS () {
      this.$emit('sms', this.reservation)
    },
    setInfusion () {
      this.isCardMenuActive = false
      this.$emit('set-infusion', this.reservation)
    }
  }
}
</script>
