<template>
  <!-- 選擇醫師 -->
  <div :class="{'modal-ui': true, 'is-active': isActive }">
    <div class="modal-ui__overlay">
      <div class="modal-ui__inner">
        <header class="modal-ui__header">
          <h3>{{title}}</h3>
          <div class="modal-ui__filter">
            <div class="modal-ui__filter-group">
              <button class="modal-ui__btn-filter" :class="{ 'is-active': popJobsIsActive}" @click="togglePopJobs()">{{selectJobName}}</button>
              <div class="modal-ui__sub-nav" :class="{ 'is-active': popJobsIsActive}">
                <button v-for="(job, index) in jobs" :key="index" class="modal-ui__btn-option" @click="onSelectJob(job.id)">{{job.name}}</button>
              </div>
            </div>
            <div class="modal-ui__filter-group">
              <button class="modal-ui__btn-filter" :class="{ 'is-active': popSpecialtiesIsActive }" @click="togglePop2aSpecialties()">{{selectSpecialtyName}}</button>
              <div class="modal-ui__sub-nav" :class="{ 'is-active': popSpecialtiesIsActive }" >
                <button v-for="(specialty, index) in specialties" :key="index" class="modal-ui__btn-option" @click="onSelectSpecialty(specialty.id)">{{specialty.name}}</button>
              </div>
            </div>
          </div>
          <div class="site-search">
            <input type="text" v-model="keyword">
            <button>搜尋</button>
          </div>
          <button class="modal-ui__btn-close" @click="onClose()"></button>
        </header>
        <div class="modal-ui__content">
          <div class="modal-ui__row">
            <h4>選擇人員</h4>
          </div>
          <div class="modal-ui__employee-list">
            <button v-for="(staff, index) in filteredStaffs" :key="index" class="modal-ui__btn-name" :class="{ 'is-active': staff.id === selectStaffId }"  @click="onSelectStaff(staff)">{{staff.fullName}}</button>
          </div>
          <div class="modal-ui__row">
            <button class="modal-ui__btn-cancel" @click="onClose()">取消</button>
            <button class="modal-ui__btn-confirm" @click="onConfirm()">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 選擇人員 -->
</template>

<script>
import {
  apiGetJobs, apiGetSpecialties, apiGetStaffs
} from '../api/v1/reservations'

export default {
  name: 'SearchReservationSelectStaff',
  props: ['isActive'],
  emits: ['on-close', 'on-confirm'],
  data () {
    return {
      staffs: [],
      keyword: '',
      selectStaffId: 0,
      selectStaffName: '',
      selectJobId: 0,
      selectSpecialtyId: 0,
      popJobsIsActive: false, // 身份選單開關
      popSpecialtiesIsActive: false, // 專長選單開關
      jobs: [],
      specialties: []
    }
  },
  created () {
  },
  computed: {
    title () {
      return '預約醫生'
    },
    filteredStaffs: function () {
      if (this.staffs) {
        return this.staffs.filter(staff => {
          return staff.fullName.includes(this.keyword)
        }).filter(staff => {
          return (this.selectJobId === 0) || (staff.jobId === this.selectJobId)
        }).filter(staff => {
          return (this.selectSpecialtyId === 0) || (staff.specialties.map(s => s.id).includes(this.selectSpecialtyId))
        })
      }
      return []
    },
    selectJobName () { return this.selectJobId === 0 ? '身份' : this.jobs[this.selectJobId].name },
    selectSpecialtyName () { return this.selectSpecialtyId === 0 ? '專長' : this.specialties[this.selectSpecialtyId].name }
  },
  watch: {
    isActive: function (nv, ov) {
      console.log(`isactive nv ${nv}, ov ${ov}`)
      if (nv === true) {
        this.fetchData()
      } else {
        this.resetData()
      }
    }
  },
  methods: {
    // 清除選擇
    resetData () {
      this.selectStaffId = 0
      this.selectJobId = 0
      this.selectSpecialtyId = 0
    },
    fetchData () {
      this.fetchStaffs()
    },
    async fetchJobs () {
      await apiGetJobs().then(data => {
        console.log(data)
        if (!data.error) {
          this.jobs = data
          this.jobs.unshift({ id: 0, name: '全部' })
        }
      })
    },
    async fetchSpecialties () {
      await apiGetSpecialties().then(data => {
        console.log(data)
        if (!data.error) {
          this.specialties = data
          this.specialties.unshift({ id: 0, name: '全部' })
        }
      })
    },
    fetchStaffs () {
      apiGetStaffs({}).then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.staffs = data.content
        }
      })
    },
    async togglePopJobs () {
      // 開啟身份選單前先讀取資料
      if (!this.popJobsIsActive) {
        await this.fetchJobs()
        this.popJobsIsActive = true
      } else {
        this.popJobsIsActive = false
      }
    },
    async togglePop2aSpecialties () {
      // 開啟專長選單前先讀取資料
      if (!this.popSpecialtiesIsActive) {
        await this.fetchSpecialties()
        this.popSpecialtiesIsActive = true
      } else {
        this.popSpecialtiesIsActive = false
      }
    },
    onSelectStaff (staff) {
      this.doSelectStaff(staff)
    },
    doSelectStaff (staff) {
      this.selectStaffId = staff.id
      this.selectStaffName = staff.fullName
    },
    onSelectJob (jobId) {
      this.selectJobId = jobId
      this.popJobsIsActive = false
    },
    onSelectSpecialty (specialtyId) {
      this.selectSpecialtyId = specialtyId
      this.popSpecialtiesIsActive = false
    },
    onClose () {
      console.log('close')
      this.$emit('on-close')
    },
    onConfirm () {
      if (this.selectStaffId === 0) {
        console.log('沒有選人')
        return
      }
      // 確定選人
      const params = {
        staffId: this.selectStaffId,
        staffName: this.selectStaffName
      }
      console.log('SearchReservationSelectStaff onConfirm')
      console.log(JSON.parse(JSON.stringify(params)))
      this.$emit('on-confirm', params)
    }
  }
}
</script>
<style lang="scss">
.modal-ui {
  &__btn-delete {
    background-color: #f52b08;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #fff;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
  &__btn-cancel {
    background-color: #ffffff;
    width: 12rem;
    text-align: center;
    line-height: 4rem;
    height: 4rem;
    text-align: center;
    border-radius: 4rem;
    color: #525252;
    border: 0;
    font-size: 1.8rem;
    margin: 0 auto;
    display: block;
    transition: all 300ms ease;
    &:active {
      background-color: #b6b6b6;
    }
  }
  .is-busy {
      color: #b6b6b6;
  }
}
</style>
