<template>
  <!--請選擇約診醫生-若該項目不用醫生，則自動替換為美療師-->
  <div class="reservation-service" :class="{ 'is-active': isActive }">
    <div class="reservation-service__header">
      <h3>請選擇服務人員</h3>
      <div class="site-search">
        <input type="text" v-model="keyword"/>
        <button>搜尋</button>
      </div>
    </div>
    <div class="reservation-service__wrap">
      <div class="reservation-service__group">
        <h4>服務人員</h4>
        <ul class="reservation-service__list">
          <li v-for="staff in filteredStaffs" :key="staff" :class="{ 'is-active': selectStaff.id === staff.id}" @click="onSelectStaff(staff)">
            {{staff.fullName}}
          </li>
        </ul>
      </div>
    </div>
    <button class="reservation-service__btn-confirm" @click="onConfirm">確定</button>
  </div>
  <!--請選擇約診醫生-若該項目不用醫生，則自動替換為美療師-->
</template>
<script>
export default {
  name: 'ReservationService',
  props: ['isActive', 'staffs'],
  computed: {
    filteredStaffs () {
      return this.staffs.filter(s => s.fullName.includes(this.keyword))
    }
  },
  data () {
    return {
      isReworkActive: false,
      selectStaff: '',
      keyword: ''
    }
  },
  methods: {
    onSelectStaff (staff) {
      this.selectStaff = staff
    },
    onConfirm () {
      if (this.selectStaff) {
        this.$emit('confirm', this.selectStaff)
      }
    }
  }
}
</script>
