<template>
<!--顧客資料&預約內容-->
<div class="reservation-client" :class="{ 'is-active': isActive }">
    <div class="reservation-client__content">
      <h3>顧客資料</h3>
      <div class="reservation-client__client-info">
          <div class="reservation-client__row">
              <span>約診顧客</span>
              <div :class="{'reservation-client': !isCompletePatient, 'reservation-client__client-selected': isCompletePatient} ">
                <!-- <div class="reservation-client__avatar"> -->
                <div class="card__avatar">
                  <img :src="photo" alt="" @error="loadDefaultPhoto">
                </div>
                <div class="reservation-client__data">
                  <p class="reservation-client__name">{{patientName}}</p>
                  <p class="reservation-client__mobile">09xxxx{{patientPhone}}</p>
                </div>
              </div>
          </div>
          <button class="reservation-client__btn-client" :class="{ 'is-complete': isCompletePatient }" @click="onQueryOrCreate">查詢 或 建立資料</button>
      </div>
  </div>
  <div class="reservation-client__content">
      <h3>預約內容</h3>
      <div class="reservation-client__wrap">
          <div class="reservation-client__item">
              <div class="reservation-client__step">A</div>
              <div class="reservation-client__detail">
                  <p class="reservation-client__type">約診項目</p>
                  <p class="reservation-client__selected">{{treatmentName}}</p>
              </div>
              <button class="reservation-client__btn-select" :class="{ 'is-complete': isCompleteA }" @click="onSelectButton('A')">選擇</button>
          </div>
          <div class="reservation-client__item">
              <div class="reservation-client__step">B</div>
              <div class="reservation-client__detail">
                  <p class="reservation-client__type">約診醫生 / 美療師 / 護理師</p>
                  <p class="reservation-client__selected">{{treatmentStaffName}}</p>
              </div>
              <button class="reservation-client__btn-select" :class="{ 'is-complete': isCompleteB }" @click="onSelectButton('B')">選擇</button>
          </div>
          <div class="reservation-client__item">
              <div class="reservation-client__step">C</div>
              <div class="reservation-client__detail">
                  <p class="reservation-client__type">約診時段</p>
                  <p class="reservation-client__selected">{{scheduleDate}}</p>
              </div>
              <button class="reservation-client__btn-select" :class="{ 'is-complete': isCompleteC }" @click="onSelectButton('C')">選擇</button>
          </div>
          <div class="reservation-client__item">
              <div class="reservation-client__step">D</div>
              <div class="reservation-client__detail">
                  <p class="reservation-client__type">服務 / 諮詢人員</p>
                  <p class="reservation-client__selected">{{ serviceStaffName }}</p>
              </div>
              <button class="reservation-client__btn-select" :class="{ 'is-complete': isCompleteD }" @click="onSelectButton('D')">選擇</button>
          </div>
      </div>
      <button class="reservation-client__btn-submit" @click="onConfirmReservation" v-if="dataReady">確定預約</button>
  </div>
</div>
<!--顧客資料&預約內容-->
</template>
<script>
import { photoUrl } from '@/api/v1/reservations'

export default {
  name: 'ReservationClient',
  props: ['isActive', 'patient', 'isCompleteA', 'isCompleteB', 'isCompleteC', 'isCompleteD', 'treatmentName', 'scheduleDate', 'treatmentStaffName', 'serviceStaffName'],
  emits: ['confirmReservation', 'selectA', 'selectB', 'selectC', 'selectD', 'createData'],
  data () {
    return {
      patientPhoto: null
      // photo: ''
    }
  },
  watch: {
    patient: function (nv, ov) {
      console.log(`patient set: ${nv.id}`)
      if (nv.photo) {
        // this.photo = 'http://' + photoUrl('patients', nv.id)
        // console.log(`photo set: ${this.photo}`)
        this.patientPhoto = nv.photo
      } else {
        this.patientPhoto = null
      }
      // else {
      //   this.loadDefaultPhoto()
      // }
    }
  },
  computed: {
    photo () {
      if (this.patientPhoto) {
        return photoUrl('patients', this.patient.id) // this.reservation.patientPhoto
      } else {
        // use default profile head icon
        return (this.patient.gender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
      }
    },
    isCompletePatient () {
      return this.patient
    },
    patientName () {
      if (this.patient && this.patient.fullName) {
        return this.patient.fullName
      }
      return ''
    },
    patientPhone () {
      if (this.patient && this.patient.phone) {
        return this.patient.phone.substr(this.patient.phone.length - 4) // server only sends last 4 digits to client
      }
      return 'XXX'
    },
    dataReady () {
      return (this.isCompleteA && this.isCompleteB && this.isCompleteC && this.isCompleteD && this.isCompletePatient)
    }
  },
  methods: {
    loadDefaultPhoto () {
      this.patientPhoto = null
    },
    // loadDefaultPhoto () {
    //   // this.photo = photoUrl('patients', this.patient.id)
    //   this.photo = (this.patient.gender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
    //   console.log('loadDefaultPhoto')
    // },
    onConfirmReservation () {
      this.$emit('confirmReservation')
    },
    // 點選擇 A~D 按鈕
    onSelectButton (step) {
      this.$emit(`select${step}`)
    },
    // 查詢或建立資料
    onQueryOrCreate () {
      this.$emit('createData')
    }
  }
}
</script>
<style scoped>
.card__avatar {
  flex-basis: 4.8rem;
  height: 4.8em;
}
</style>
