<template>
  <!--請選擇約診醫生-若該項目不用醫生，則自動替換為美療師-->
  <div class="reservation-identity" :class="{ 'is-active': isActive }">
    <div class="reservation-identity__header">
      <h3>請選擇約診醫生</h3>
      <div class="site-search">
        <input type="text" v-model="keyword"/>
        <button>搜尋</button>
      </div>
    </div>
    <div class="reservation-identity__wrap">
      <div class="reservation-identity__group" v-if="doctors.length > 0">
        <h4>醫生</h4>
        <ul class="reservation-identity__list">
          <li v-for="staff in doctors" :key="staff" :class="{ 'is-active': selectStaff.id === staff.id}" @click="onSelectStaff(staff)">
            {{staff.fullName}}
          </li>
        </ul>
      </div>
      <div class="reservation-identity__group" v-if="beauticians.length > 0">
        <h4>美容師</h4>
        <ul class="reservation-identity__list">
          <li v-for="staff in beauticians" :key="staff" :class="{ 'is-active': selectStaff.id === staff.id}" @click="onSelectStaff(staff)">
            {{staff.fullName}}
          </li>
        </ul>
      </div>
      <div class="reservation-identity__group" v-if="nurses.length > 0">
        <h4>護理師</h4>
        <ul class="reservation-identity__list">
          <li v-for="staff in nurses" :key="staff" :class="{ 'is-active': selectStaff.id === staff.id}" @click="onSelectStaff(staff)">
            {{staff.fullName}}
          </li>
        </ul>
      </div>
    </div>
    <button class="reservation-identity__btn-confirm" @click="onConfirm">確定</button>
  </div>
  <!--請選擇約診醫生-若該項目不用醫生，則自動替換為美療師-->
</template>
<script>
export default {
  name: 'ReservationIdentity',
  props: ['isActive', 'staffs'],
  emits: ['confirm'],
  computed: {
    // jobs: [{"id":1,"name":"醫生"},{"id":2,"name":"護理師"},{"id":3,"name":"美容師"},{"id":4,"name":"諮詢師"},{"id":5,"name":"控場"}]
    doctors () {
      return this.staffs.filter(s => s.jobId === 1 && s.fullName.includes(this.keyword))
    },
    beauticians () {
      return this.staffs.filter(s => s.jobId === 3 && s.fullName.includes(this.keyword))
    },
    nurses () {
      return this.staffs.filter(s => s.jobId === 2 && s.fullName.includes(this.keyword))
    }
  },
  watch: {
    isActive () {
      this.selectStaff = ''
    }
  },
  data () {
    return {
      selectStaff: '',
      keyword: ''
    }
  },
  methods: {
    onSelectStaff (staff) {
      this.selectStaff = staff
    },
    onConfirm () {
      if (this.selectStaff) {
        this.$emit('confirm', this.selectStaff)
      }
    }
  }
}
</script>
