<template>
  <!--請選擇約診項目-->
  <div class="reservation-type" :class="{ 'is-active': isActive }">
    <div class="reservation-type__header">
      <h3>請選擇約診項目</h3>
      <div class="site-search">
        <input type="text" v-model="keyword" />
        <button>搜尋</button>
      </div>
    </div>
    <div class="reservation-type__wrap">
      <div class="reservation-type__group" v-for="(treatmentType, index) in treatmentTypes" :key="index">
        <h4>{{treatmentType.name}}</h4>
        <ul class="reservation-type__list">
          <li v-for="treatment in treatmentType.treatments.filter(h => h.name.includes(this.keyword))" :key="treatment" :class="{ 'is-active': selectedTreatment.id === treatment.id}" @click="onSelectTreatment(treatment)">
            {{treatment.name}}
          </li>
        </ul>
      </div>
    </div>
    <button class="reservation-type__btn-confirm" @click="onConfirm">確定</button>
  </div>
  <!--請選擇約診項目-->
</template>
<script>
export default {
  name: 'ReservationType',
  props: ['isActive', 'treatmentTypes'],
  emits: ['confirm-treatment'],
  data () {
    return {
      keyword: '',
      selectedTreatment: ''
    }
  },
  computed: {
  },
  methods: {
    onConfirm () {
      if (this.selectedTreatment) {
        this.$emit('confirm-treatment', this.selectedTreatment)
      } else {
        this.$swal('請選擇約診項目', '', 'warning')
      }
    },
    onSelectTreatment (treatment) {
      this.selectedTreatment = treatment
    }
  }
}
</script>
