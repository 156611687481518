<!-- 逾期未報到 -->
<template>
  <div class="card card--overdue">
    <header class="card__header">
      <div class="card__avatar-wrap">
        <div class="card__avatar">
          <img :src="photo" alt="">
        </div>
        <div class="card__vip">
          <p>{{reservation.patientType}}</p>
          <p>{{reservation.patientPhoneLast4Digits}}</p>
        </div>
      </div>
      <div class="card__detail">
        <div class="card__info">
          <span class="card__name">{{reservation.patientName}}</span>
          <span :class="serviceStaffColorClass">{{reservation.serviceStaffName}}</span>
          <button :class="{'card-btn-menu':true, 'is-active':isCardMenuActive}" @click="toggleCardMenu"></button>
          <div :class="{'card-option':true, 'is-active':isCardMenuActive}">
            <button class="card-option__btn" @click="setInfusion">點滴設定</button>
            <button class="card-option__btn" @click="fillClientData">客戶資料</button>
          </div>
        </div>
        <div class="card__course">
          <span class="card__course-name">{{reservation.treatmentName}}</span>
          <span class="card__doctor-name">{{reservation.treatmentStaffName}}</span>
        </div>
        <div class="card__location">
          預約時間：<span>{{reservation.scheduleTime}}</span>
        </div>
      </div>
    </header>
    <footer class="card__footer">
      <button class="card__btn" @click="onCheckin">報到</button>
      <button class="card__btn" @click="onCancel">取消</button>
      <button class="card__btn" @click="onReschedule">改期</button>
      <button class="card__btn" @click="onSMS">簡訊提醒</button>
    </footer>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { photoUrl } from '@/api/v1/reservations'

export default {
  name: 'CardOverdue',
  props: ['reservation'],
  emits: ['checkin', 'cancel', 'reschedule', 'sms', 'set-infusion', 'fill-data'],
  data () {
    return {
      isCardMenuActive: false
    }
  },
  computed: {
    serviceStaffColorClass () {
      switch (this.reservation.serviceStaffJob) {
        case '醫生':
          return 'identity identity--doctors'
        case '護理師':
          return 'identity identity--nurse'
        case '美容師':
          return 'identity identity--beautician'
        case '諮詢師':
          return 'identity identity--consultant'
        default:
          return 'identity identity--control'
      }
    },
    photo () {
      if (this.reservation.patientPhoto) {
        return photoUrl('patients', this.reservation.patientId) // this.reservation.patientPhoto
      } else {
        // use default profile head icon
        return (this.reservation.patientGender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
      }
    }
  },
  methods: {
    toggleCardMenu () {
      this.isCardMenuActive = !this.isCardMenuActive
    },
    onCheckin () {
      this.$emit('checkin', this.reservation)
    },
    onCancel () {
      this.$emit('cancel', this.reservation)
    },
    onReschedule () {
      this.$emit('reschedule', this.reservation)
    },
    onSMS () {
      this.$emit('sms', this.reservation)
    },
    fillClientData () {
      this.isCardMenuActive = false
      this.$emit('fill-data', this.reservation)
    },
    setInfusion () {
      this.isCardMenuActive = false
      this.$emit('set-infusion', this.reservation)
    },
    ...mapActions(
      'modalUI', ['showPop', 'closePop']
    )
  }
}
</script>
