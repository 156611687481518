<template>
  <div class="normal-screen">
    <!--即將報到-->
    <div class="panel panel--upcoming" v-if="isAdminOrFrontDesk">
      <header class="panel__header">
        <h2>即將報到</h2>
        <div class="site-search">
          <input type="text" v-model="keyword">
          <button>搜尋</button>
        </div>
      </header>
      <main class="panel__main panel__main--upcoming" :class="{'panel__main--empty':filteredUpcoming.length==0}">
        <!--card-諮詢師-->
        <template v-for="(reservation, index) in filteredUpcoming" :key="index">
          <CardUpcoming v-bind:reservation="reservation" @set-infusion="onSetInfusion" @cancel="onCancel" @reschedule="onReschedule" @sms="onSMS" @checkin="onCheckin" @fillData="onPromptFillData" />
        </template>
        <!--card-->
      </main>
    </div>
    <!--即將報到-->
    <!--逾期未報到-->
    <div class="panel panel--overdue" v-if="isAdminOrFrontDesk">
      <header class="panel__header">
        <h2>逾期未報到</h2>
      </header>
      <main class="panel__main" :class="{'panel__main--empty':filteredOverdue.length==0}">
        <!--card-諮詢師-->
        <template v-for="(reservation, index) in filteredOverdue" :key="index">
          <CardOverdue v-bind:reservation="reservation" @set-infusion="onSetInfusion" @cancel="onCancel" @reschedule="onReschedule" @sms="onSMS" @checkin="onCheckin" @fillData="onPromptFillData" />
        </template>
        <!--card-->
      </main>
    </div>
    <!--逾期未報到-->
    <!--預約-->
    <div class="panel panel--reservation" :style="{position: isAdminOrFrontDesk ? 'relative':'absolute'}">
      <header class="panel__header">
        <h2><button class="panel__btn-back" v-if="!isShowReservationSearchBox" @click="onBackButton" />預約</h2>
        <front-desk-menu :activeItem="'nscreen'" />
      </header>
      <main class="panel__main">
        <button class="btn-reservation" @click="onBtnReservation"></button>
        <!--預約查詢-->
        <div class="reservation-search" :class="{ 'is-active': isShowReservationSearchBox }">
          <header class="reservation-search__header">
            <h2>預約查詢</h2>
            <button class="reservation-search__btn-close" @click="onCloseReservationSearchBox"></button>
          </header>
          <div class="reservation-search__content">
            <h3>請輸入下方任一資料</h3>
            <div class="reservation-search__form">
              <div class="reservation-search__row">
                <label for="searchKeyword">關鍵字</label>
                <input type="text" id="searchKeyword" placeholder="如：姓名、身分證、手機號碼" v-model="searchKeyword">
              </div>
              <div class="reservation-search__row">
                <label for="searchDate">預約日期</label>
                <input type="date" id="searchDate"  v-model="searchDate">
              </div>
              <div class="reservation-search__row">
                <label for="searchTreatment">預約項目</label>
                <input type="text" id="searchTreatment" v-model="searchTreatment">
              </div>
              <div class="reservation-search__row">
                <label for="searchDoctor">預約醫生</label>
                <input type="text" id="searchDoctor" v-model="searchDoctor" @click="onClickSearchDoctor">
              </div>
              <div class="reservation-search__row">
                <button class="reservation-search__btn-search" @click="onSearch">搜尋</button>
              </div>
            </div>
          </div>
        </div>
        <!--預約查詢-->
        <!--預約查詢結果-->
        <div class="reservation-result" :class="{ 'is-active': !isShowReservationSearchBox && !isReservationClientActive }">
          <div class="reservation-result__search">
            <div class="site-search">
              <input type="text" v-model="searchFilterKeyword">
              <button>搜尋</button>
            </div>
          </div>
          <div class="reservation-result__content">
            <div class="reservation-result__empty" v-if="filteredSearchResults.length == 0">
                <img src="@/assets/images/icon-empty.svg" alt="沒有資料，請試試其它搜尋條件">
                <p>沒有資料，請試試其它搜尋條件</p>
            </div>
            <!--card-reservation-->
            <template v-for="(reservation, index) in filteredSearchResults" :key="index">
              <CardReservation v-bind:reservation="reservation" @set-infusion="onSetInfusion" @cancel="onCancel" @reschedule="onReschedule" @sms="onSMS" @checkin="onCheckin" />
            </template>
            <!--card-reservation-->
          </div>
        </div>
        <!--預約查詢結果-->
        <!--顧客資料&預約內容 A / B / C / D-->
        <ReservationClient
          :isActive="isReservationClientActive"
          :patient="reservePatient"
          :isCompleteA="isCompleteA"
          :isCompleteB="isCompleteB"
          :isCompleteC="isCompleteC"
          :isCompleteD="isCompleteD"
          :treatmentName="treatmentName"
          :scheduleDate="scheduleDate"
          :treatmentStaffName="treatmentStaffName"
          :serviceStaffName="serviceStaffName"
          @createData="onCreateData"
          @confirmReservation="onConfirmReservation"
          @selectA="onSelectA"
          @selectB="onSelectB"
          @selectC="onSelectC"
          @selectD="onSelectD"
        />
        <!--顧客資料&預約內容-->
        <!--初診客戶資料建立-->
        <!-- <CreateClientData
          :isActive="createClientDataIsActive"
          @close="onCloseCreateClientData"
        /> -->
        <!--初診客戶資料建立-->
        <div class="create-client-data" :class="{ 'is-active': createClientDataIsActive }">
          <div class="create-client-data__overlay">
            <div class="create-client-data__inner">
              <header class="create-client-data__header">
                <h2>{{fillClientDataIsActive?'客戶資料變更':'初診客戶資料建立'}}</h2>
                <button class="create-client-data__btn-close" @click="onCloseCreateClientData"></button>
              </header>
              <div class="create-client-data__content">
                <!--搜尋是否為回診客人-->
                <div class="create-client-data__search" v-if="!fillClientDataIsActive">
                  <h3>搜尋是否為回診客人</h3>
                  <div class="create-client-data__form">
                    <div class="create-client-data__row">
                      <label for="client-search-keyword">關鍵字</label>
                      <input type="text" id="client-search-keyword" placeholder="如：姓名、手機、身分證號" v-model="clientSearchKeyword" v-on:keyup.enter="onClientSearch">
                    </div>
                    <div class="reservation-search__row">
                      <label for="client-search-birthday">生日</label>
                      <input type="text" id="client-search-birthday" placeholder="如：65/05/31" v-model="clientSearchBirthday" v-on:keyup.enter="onClientSearch">
                    </div>
                    <div class="create-client-data__row">
                      <button class="create-client-data__btn-search" @click="onClientSearch">搜尋</button>
                    </div>
                  </div>
                </div>
                <!--搜尋是否為回診客人-->
                <!--搜尋結果-->
                <div class="search-client-data" :class="{ 'is-active': clientSearchResultIsActive}">
                  <h3>搜尋結果</h3>
                  <button class="search-client-data__btn-close" @click="onCloseClientSearchResult"></button>
                  <div class="search-client-data__wrap">
                    <!--search-result-item-->
                    <CardClientSearchResultItem :patient="patient" @click="onSelectClientSearchResultItem(patient)"  v-for="(patient, index) in clientSearchResult" :key="index"/>
                    <!--search-result-item-->
                  </div>
                </div>
                <!--搜尋結果-->
                <!--建立資料-->
                <div class="create-client-data__create">
                  <h3>{{fillClientDataIsActive?'填寫資料':'建立資料'}}</h3>
                  <div class="create-client-data__form">
                    <div class="create-client-data__row">
                      <label for="name">姓名</label>
                      <input type="text" id="name" placeholder="" v-model="patientName"/>
                    </div>
                    <div class="create-client-data__row">
                      <label for="birthday">生日</label>
                      <input type="date" id="birthday" placeholder="" v-model="patientBirthdate"/>
                    </div>
                    <div class="create-client-data__row">
                      <label for="phone">電話</label>
                      <input type="text" id="phone" placeholder="" v-model="patientPhone"/>
                    </div>
                    <div class="create-client-data__row">
                      <label for="id">身分證字號</label>
                      <input type="text" id="id" placeholder="" v-model="patientIdNumber"/>
                    </div>
                    <div class="create-client-data__row">
                      <label for="id">分類</label>
                      <div class="create-client-data__select-wrap">
                        <select name="type" id="type" v-model="selectedPatientType">
                          <option  v-for="(patientType, index) in patientTypes" :key="index" :value="patientType.id">{{patientType.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="create-client-data__row">
                      <button class="create-client-data__btn-save" @click="onCreateClientDataSave">儲存</button>
                    </div>
                  </div>
                </div>
                <!-- end 建立資料-->
                <!-- 更新資料 -->
                <!-- <FillClientData :is-active="fillClientDataIsActive" :patient-id="fillClientDataPatientId" @close="onFillClientDataClose" @saved="onFillClientDataSaved" /> -->
                <!-- end 更新資料 -->
              </div>
            </div>
          </div>
        </div>
        <!--初診客戶資料建立-->
        <!--初診客戶資料建立-->
        <!--請選擇約診項目-->
        <ReservationType :isActive="reservationTypeIsActive" @confirm-treatment="onConfirmTreatment" :treatmentTypes="treatmentTypes"/>
        <!--請選擇約診項目-->
        <!--請選擇約診醫生-若該項目不用醫生，則自動替換為美療師-->
        <ReservationIdentity :isActive="reservationIdentityIsActive" :staffs="staffs" @confirm="onConfirmDoctor"/>
        <!--請選擇約診醫生-若該項目不用醫生，則自動替換為美療師-->
        <!--請選擇約診時段-->
        <ReservationTime :isActive="reservationTimeIsActive" :treatmentId="reserveTreatment.id" :staff="reserveTreatmentStaff" :isMedical="reserveTreatment.isMedical" @confirm="onConfirmTime"/>
        <!--請選擇約診時段-->
        <!--請選擇服務人員-->
        <ReservationService :isActive="reservationServiceIsActive" :staffs="staffs" @confirm="onConfirmService"/>
        <!--請選擇服務人員-->
        <!--預約已完成-->
        <div class="confirm-ui" id="complete" :class="{'is-active':isConfirmUIActive}">
          <div class="confirm-ui__overlay">
            <div class="confirm-ui__inner">
              <div class="confirm-ui__icon">
                <img src="@/assets/images/icon-exclamation.svg" alt="">
              </div>
              <div class="confirm-ui__content">
                <h2>預約已完成</h2>
                <p></p>
              </div>
              <button class="confirm-ui__btn-add-other" @click="onOrderMore">加選其他療程</button>
              <button class="confirm-ui__btn-finish" @click="onEndOrder">結束預約</button>
            </div>
          </div>
        </div>
        <!--預約已完成-->
      </main>
    </div>
    <!--預約-->
  <ModalUI :reservation=popReservation />
  <SearchReservationSelectStaff :is-active="isShowSearchReservationSelectStaff" @on-close="onCloseSearchReservationSelectStaff" @on-confirm="onConfirmSearchReservationSelectStaff" />
  <loading :isActive="isLoading" />
  </div>
</template>
<script>
import ModalUI from '@/components/ModalUI.vue'
import CardUpcoming from '../components/CardUpcoming'
import CardOverdue from '../components/CardOverdue'

import CardReservation from '../components/CardReservation'
import ReservationClient from '../components/ReservationClient'
// import CreateClientData from '../components/CreateClientData'
import ReservationType from '../components/ReservationType'
import ReservationTime from '../components/ReservationTime'
import ReservationIdentity from '../components/ReservationIdentity'
import ReservationService from '../components/ReservationService'
// import FillClientData from '../components/FillClientData'
import SearchReservationSelectStaff from '../components/SearchReservationSelectStaff'
import { Role } from '../constants/role'

import { mapActions } from 'vuex'
import {
  apiGetReservations,
  apiCheckin,
  apiGetPatientTypes,
  apiListPatients,
  apiGetPatient,
  apiUpdatePatient,
  apiCreatePatient,
  apiGetTreatmentTypes,
  apiGetStaffs,
  apiGetDoctors,
  apiMakeReservation,
  apiCancel,
  apiGetResheduleData,
  photoUrl,
  apiGetPatientById
} from '../api/v1/reservations'
import { formatDate, serviceStaffColorClass } from '../utils/utils'
import Loading from '../components/Loading.vue'
import FrontDeskMenu from '../components/FrontDeskMenu.vue'
import CardClientSearchResultItem from '../components/CardClientSearchResultItem.vue'

export default {
  name: 'NSCreen',
  components: {
    ModalUI,
    CardUpcoming,
    CardOverdue,
    CardReservation,
    ReservationClient,
    ReservationType,
    ReservationTime,
    ReservationIdentity,
    ReservationService,
    Loading,
    FrontDeskMenu,
    SearchReservationSelectStaff,
    CardClientSearchResultItem
  },
  data () {
    return {
      isSubmitting: false,
      //
      isLoading: false,
      photo: '',
      keyword: '',
      searchKeyword: '',
      searchFilterKeyword: '',
      searchDate: '',
      searchTreatment: '',
      searchDoctor: '',
      popReservation: '', // 傳入 modal-ui 的訂單資料
      searchResults: [],
      upcoming: [],
      overdue: [],
      timer: '',
      // 預約相關
      isShowReservationSearchBox: true, // 預約查詢表格
      isReservationClientActive: false, // 預約步驟 UI 開關
      createClientDataIsActive: false, // 建立初診資料 UI 開關
      reservationTypeIsActive: false, // A 約診項目 UI 開關
      reservationIdentityIsActive: false, // B 約診醫生 / 美療師 UI 開關
      reservationTimeIsActive: false, // C 約診時段 UI 開關
      reservationServiceIsActive: false, // D 服務人員 UI 開關
      isConfirmUIActive: false, // 預約完成 預約其他療程或結束預約 UI 開關
      reservePatient: '', // 顧客資料
      reserveTreatment: '', // A 約診項目
      reserveTime: '', // B 約診時間
      reserveTreatmentStaff: '', // C 約診醫師
      reserveServiceStaff: '', // D 服務人員
      // 客戶搜尋
      clientSearchResultIsActive: false,
      clientSearchKeyword: '',
      clientSearchBirthday: '',
      clientSearchResult: [],
      // 建立基本資料
      patientTypes: [],
      idNumber: '',
      patientName: '',
      patientBirthdate: '',
      patientPhone: '',
      patientIdNumber: '',
      patientTypeId: '',
      selectedPatientType: 1,
      // 選療程
      treatmentTypes: [],
      // 選醫生
      staffs: [],
      // 變更客戶資料
      fillClientDataIsActive: false,
      fillClientDataPatientId: '',
      // 預約查詢
      isShowSearchReservationSelectStaff: false
    }
  },
  created () {
    this.loadData()
    this.timer = setInterval(this.loadData, 1000)
    this.searchDate = '' // formatDate(new Date())
    this.photo = photoUrl('staffs', this.$store.state.auth.user.id)
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
  computed: {
    username () {
      return this.$store.state.auth.user.full_name
    },
    isAdminOrFrontDesk () {
      return this.$store.state.auth.user && [Role.Admin, Role.FrontDesk].includes(this.$store.state.auth.user.roles[0])
    },
    filteredUpcoming () {
      return this.upcoming.filter(reservation => reservation.patientName.includes(this.keyword))
    },
    filteredOverdue () {
      return this.overdue.filter(reservation => reservation.patientName.includes(this.keyword))
    },
    filteredSearchResults () {
      return this.searchResults.filter(reservation => reservation.patientName.includes(this.searchFilterKeyword))
    },
    // A 約診項目
    isCompleteA () {
      return this.reserveTreatment
    },
    // B 約診醫生
    isCompleteB () {
      return this.reserveTreatmentStaff
    },
    // C 約診時段
    isCompleteC () {
      return this.reserveTime
    },
    // D 服務人員
    isCompleteD () {
      return this.reserveServiceStaff
    },
    treatmentName () {
      return this.reserveTreatment ? this.reserveTreatment.name : ''
    },
    scheduleDate () {
      return this.reserveTime
    },
    treatmentStaffName () {
      return this.reserveTreatmentStaff ? this.reserveTreatmentStaff.fullName : ''
    },
    serviceStaffName () {
      return this.reserveServiceStaff ? this.reserveServiceStaff.fullName : ''
    }
  },
  methods: {
    loadDefaultPhoto () {
      this.photo = (this.$store.state.auth.user.gender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
    },
    // --- 預約  --- //
    resetReserveData () {
      this.reservePatient = ''
      this.reserveTreatment = ''
      this.reserveTime = ''
      this.reserveTreatmentStaff = ''
      this.reserveServiceStaff = ''
      this.staffs = []
      this.treatmentTypes = []
    },
    // 客戶資料
    resetPatientData () {
      this.reservePatient = ''
      this.idNumber = ''
      this.patientName = ''
      this.patientBirthdate = ''
      this.patientPhone = ''
      this.patientIdNumber = ''
      this.patientTypeId = ''
      this.selectedPatientType = 1
      this.clientSearchKeyword = ''
      this.clientSearchBirthday = ''
    },
    // 查詢或建立資料
    async onCreateData () {
      this.resetPatientData()
      this.fillClientDataIsActive = false
      await apiGetPatientTypes().then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.patientTypes = data
        }
        this.createClientDataIsActive = true
      })
    },
    onCloseCreateClientData () {
      this.createClientDataIsActive = false
      this.onFillClientDataClose()
    },
    /**
     * @deprecated 2021-12-04
     * @description Search for single client
     */
    onCreateClientDataSearch () {
      this.reservePatient = ''
      // idNumber
      this.isLoading = true
      apiGetPatient({ idNumber: this.idNumber }).then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
          this.$swal('無資料', '', 'warning')
        } else {
          this.reservePatient = data
          this.patientName = data.fullName
          this.patientBirthdate = data.birthDate
          // 2021-07-13 若客戶給人員電話輸入要預約，應該幫客人隱藏身份證號。
          // 若客人給身份證號，要幫客人隱藏電話。
          if (this.idNumber === data.phone) {
            this.patientPhone = data.phone
          } else if (this.idNumber === data.idNumber) {
            this.patientIdNumber = data.idNumber
          }
          this.patientTypeId = data.patientTypeId
          this.selectedPatientType = data.patientTypeId
          // 輸入電話或身分證，就直接進入ABCD介面，不帶入資料
          this.createClientDataIsActive = false
        }
      }).catch(e => {
        console.log(e.data)
        this.$swal('無資料', '請輸入完整身分證字號或電話號碼查詢。', 'warning').then(() => {
          this.resetPatientData()
        })
      }).finally(() => {
        console.log('end')
        this.isLoading = false
      })
    },
    /**
     * 2021.12.04.
     * blur search for existing client in name, phone, or id number
     */
    onClientSearch () {
      if (this.clientSearchKeyword === '' && this.clientSearchBirthday === '') return
      let birthDate = ''
      if (this.clientSearchBirthday !== '') {
        const bdParts = this.clientSearchBirthday.split('/')
        if (bdParts.length !== 3) {
          this.$swal('生日格式錯誤', '請輸入正確民國生日格式，如 71/07/12', 'warning')
          return
        }
        // 民國轉西元
        const y = bdParts[0] < 1911 ? parseInt(bdParts[0]) + 1911 : bdParts[0]
        birthDate = `${y}-${bdParts[1]}-${bdParts[2]}`
      }
      this.reservePatient = ''
      this.isLoading = true
      const pageSize = 200
      const params = {
        page: 1,
        size: pageSize,
        keyword: this.clientSearchKeyword,
        birthDate: birthDate
      }
      apiListPatients(params).then(data => {
        if (!data.error) {
          if (data.totalElements === 0) {
            // no result
            this.$swal('無資料', '請輸入其他條件進行查詢。', 'warning')
          } else if (data.totalElements === 1) {
            // only one result, select directly
            this.onSelectClientSearchResultItem(data.content[0])
          } else if (data.totalPages > 1) {
            // too many results
            this.$swal('太多結果', `搜尋結果超過${pageSize}筆，請輸入更精確的關鍵字進行查詢。`, 'warning')
          } else {
            // 顯示列表
            this.clientSearchResult = data.content
            this.clientSearchResultIsActive = true
          }
        }
      }).catch(e => {
        console.log(e.data)
        this.$swal('無資料', '請輸入其他條件進行查詢。', 'warning').then(() => {
          this.resetPatientData()
        })
      }).finally(() => {
        console.log('end')
        this.isLoading = false
      })
    },
    onCloseClientSearchResult () {
      this.clientSearchResultIsActive = false
    },
    onSelectClientSearchResultItem (patient) {
      this.reservePatient = patient
      this.clientSearchResultIsActive = false
      this.createClientDataIsActive = false
    },
    onCreateClientDataSave () {
      this.patientTypeId = this.selectedPatientType
      const api = (this.reservePatient ? apiUpdatePatient : apiCreatePatient)
      const data = {
        fullName: this.patientName,
        idNumber: this.patientIdNumber,
        birthDate: this.patientBirthdate,
        phone: this.patientPhone,
        patientTypeId: this.patientTypeId
      }
      const params = {
        patientId: (this.reservePatient ? this.reservePatient.id : 0),
        data: data
      }
      console.log(params)
      api(params).then(data => {
        console.log(data)
        if (data.error) {
          // console.log(data.error.message)
          this.$swal('儲存失敗！', data.error.message, 'error')
        } else {
          this.reservePatient = data
          this.createClientDataIsActive = false
          this.onFillClientDataClose()
        }
      }).catch(err => {
        this.$swal('建立失敗', err.data.error.message, 'error')
        console.log(err.data.error)
      })
    },
    // 確認預約
    onConfirmReservation () {
      if (this.isSubmitting) {
        console.log('isSubmitting already')
        return
      }
      this.isSubmitting = true
      // call api
      const params = {
        patientId: this.reservePatient.id,
        treatmentId: this.reserveTreatment.id,
        scheduleDateTime: this.reserveTime,
        treatmentStaffId: this.reserveTreatmentStaff.id,
        serviceStaffId: this.reserveServiceStaff.id
      }
      apiMakeReservation(params).then(data => {
        console.log(data)
        if (data.error) {
          // alert(data.error.message)
          this.$swal('預約失敗！', data.error.message, 'error')
        } else {
          // success!
          this.isConfirmUIActive = true
        }
      }).catch(e => {
        this.$swal('預約失敗！', '', 'error')
      }).finally(() => {
        this.isSubmitting = false
      })
    },
    // 檢查輸入 4 步驟
    checkReserveSteps (step) {
      if (['A', 'B', 'C', 'D'].includes(step) && !this.reservePatient) {
        this.$swal('請先輸入約診顧客', '', 'warning')
        return false
      }
      if (['B', 'C', 'D'].includes(step) && !this.isCompleteA) {
        this.$swal('請先選擇約診項目', '', 'warning')
        return false
      }
      if (['C', 'D'].includes(step) && !this.isCompleteB) {
        this.$swal('請先選擇約診醫生/美療師', '', 'warning')
        return false
      }
      if (['D'].includes(step) && !this.isCompleteC) {
        this.$swal('請先選擇約診時段', '', 'warning')
        return false
      }
      return true
    },
    // A. 約診項目
    async onSelectA () {
      if (!this.checkReserveSteps('A')) {
        return
      }
      await apiGetTreatmentTypes().then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.treatmentTypes = data
          // 顯示約診項目選項
          this.reservationTypeIsActive = true
        }
      })
    },
    onConfirmTreatment (treatment) {
      // 如果換新的項目，清除 B & C
      if (this.reserveTreatment.id !== treatment.id) {
        this.reserveTime = ''
        this.reserveTreatmentStaff = ''
      }
      this.reserveTreatment = treatment
      this.reservationTypeIsActive = false
    },
    // 選擇醫生
    onSelectB () {
      if (!this.checkReserveSteps('B')) {
        return
      }
      this.staffs = []
      apiGetDoctors(this.reserveTreatment.id).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.staffs = data
          this.reserveTime = '' // clear time
          this.reservationIdentityIsActive = true
        }
      })
    },
    onConfirmDoctor (staff) {
      this.reserveTreatmentStaff = staff
      this.reservationIdentityIsActive = false
    },
    // 選擇時段 --> 必須先選醫生
    onSelectC () {
      if (!this.checkReserveSteps('C')) {
        return
      }
      // TODO: get initial available times
      this.reservationTimeIsActive = true
    },
    onConfirmTime (dateTime) {
      console.log('selected ' + dateTime)
      this.reserveTime = dateTime
      this.reservationTimeIsActive = false
    },
    // 選服務人員
    onSelectD () {
      if (!this.checkReserveSteps('D')) {
        return
      }
      this.staffs = []
      apiGetStaffs({}).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.staffs = data.content
          this.reservationServiceIsActive = true
        }
      })
    },
    onConfirmService (staff) {
      this.reserveServiceStaff = staff
      this.reservationServiceIsActive = false
    },
    onBackButton () {
      this.createClientDataIsActive = false
      // Close A ~ D dialogs if open
      if (this.reservationTypeIsActive || this.reservationTimeIsActive || this.reservationIdentityIsActive || this.reservationServiceIsActive) {
        this.reservationTypeIsActive = false
        this.reservationTimeIsActive = false
        this.reservationIdentityIsActive = false
        this.reservationServiceIsActive = false
      } else {
      // Close 預約步驟 dialog, 顯示預約查詢 dialog
        this.isShowReservationSearchBox = true
        this.resetSearchParams()
        this.isReservationClientActive = false
      }
    },
    onOrderMore () {
      this.reserveTreatment = ''
      this.reserveTime = ''
      this.reserveTreatmentStaff = ''
      this.reserveServiceStaff = ''
      this.isConfirmUIActive = false
    },
    onEndOrder () {
      this.resetReserveData()
      this.resetPatientData()
      this.isConfirmUIActive = false
    },
    // --- Others --- //
    loadData () {
      // console.log('loading data...')
      const params = {
        start: formatDate(new Date()),
        end: formatDate(new Date()),
        status: 'RESERVED,LATE'
      }
      apiGetReservations(params).then(data => {
        if (data.content) {
          // console.log(data.content)
          this.upcoming = data.content.filter(reservation => reservation.status === 'RESERVED')
          this.overdue = data.content.filter(reservation => reservation.status === 'LATE')
        } else {
          if (data.error) {
            console.log(data.error.message)
            // alert(data.error.message)
          }
        }
      })
    },
    // 報到
    onCheckin (reservation) {
      // 2021-07-13 檢查客戶是否有填資料
      apiGetPatientById(reservation.patientId).then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
          this.$swal('無資料', '查無此客戶資料', 'warning')
        } else {
          if (data.idNumber) {
            this.doCheckin(reservation)
          } else {
            this.$swal('資料不完整', '請先點選「... > 客戶資料」補完客戶資料並儲存後，再進行報到。', 'warning')
          }
        }
      }).finally(() => {
        console.log('end')
      })
    },
    doCheckin (reservation) {
      apiCheckin({
        reservationId: reservation.id,
        data: {}
      }).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          console.log(data.message)
        }
      })
    },
    // 設定點滴
    onSetInfusion (reservation) {
      this.popReservation = reservation
      this.showPop({ modalId: '6a', reservationId: reservation.id })
    },
    // 取消
    onCancel (reservation) {
      const params = {
        reservationId: reservation.id
      }
      apiCancel(params).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          console.log(data.message)
          // filter out the cancelled search result
          this.searchResults = this.searchResults.filter(r => r.id !== reservation.id)
        }
      })
    },
    // 改期
    onReschedule (reservation) {
      // 填寫顧客、預約項目、醫師，開啟預約對話框
      // 取消原預約
      apiGetResheduleData(reservation.id).then(data => {
        if (!data.error) {
          this.onCancel(reservation)
          this.onBtnReservation()
          this.reservePatient = data.patient
          this.reserveTreatment = data.treatment
          this.reserveTreatmentStaff = data.treatmentStaff
          this.reserveServiceStaff = data.serviceStaff
        }
      })
    },
    // 簡訊通知
    onSMS (reservation) {

    },
    onCloseReservationSearchBox () {
      this.isShowReservationSearchBox = false
    },
    onBtnReservation () {
      this.resetReserveData()
      // 開啟預約流程對話框，關閉其他。
      this.isReservationClientActive = true
      this.isShowReservationSearchBox = false
    },
    resetSearchParams () {
      this.searchDate = ''
      this.searchKeyword = ''
      this.searchTreatment = ''
      this.searchDoctor = ''
    },
    // 變更客戶資料
    onPromptFillData (reservation) {
      this.$swal.fire({
        title: '變更客戶資料',
        text: '確定要變更客戶資料嗎？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'red',
        confirmButtonText: '確定',
        cancelButtonText: '取消'
      }).then((result) => {
        if (result.isConfirmed) {
          this.onFillData(reservation)
        }
      })
    },
    onFillData (reservation) {
      this.isLoading = true
      apiGetPatientById(reservation.patientId).then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
          this.$swal('無資料', '', 'warning')
        } else {
          this.onCreateData()
          this.reservePatient = data
          this.patientName = data.fullName
          // this.patientBirthdate = data.birthDate
          this.patientPhone = data.phone
          // this.patientIdNumber = data.idNumber
          this.patientTypeId = data.patientTypeId
          this.selectedPatientType = data.patientTypeId
        }
      }).finally(() => {
        console.log('end')
        this.isLoading = false
        this.fillClientDataPatientId = reservation.patientId
        this.fillClientDataIsActive = true
      })
    },
    // 關閉「變更客戶資料」介面
    onFillClientDataClose () {
      if (this.fillClientDataIsActive) {
        this.fillClientDataIsActive = false
        this.resetPatientData()
      }
    },
    // 預約搜尋，醫生欄位要用選人的，不用輸入
    onClickSearchDoctor () {
      this.isShowSearchReservationSelectStaff = true
    },
    onCloseSearchReservationSelectStaff () {
      this.isShowSearchReservationSelectStaff = false
    },
    onConfirmSearchReservationSelectStaff (staff) {
      this.searchDoctor = staff.staffName
      this.isShowSearchReservationSelectStaff = false
    },
    // 預約查詢
    onSearch () {
      // const date = (this.searchDate.length === 0 ? formatDate(new Date()) : this.searchDate)
      const params = {
        // start: date,
        // end: date,
        date: this.searchDate,
        status: 'RESERVED,ATTENDED,INPROGRESS,DONE,LATE,NOSHOW', // 'RESERVED,ATTENDED,INPROGRESS,DONE,LATE,NOSHOW,CANCELLED'
        keyword: this.searchKeyword,
        treatmentName: this.searchTreatment,
        doctorName: this.searchDoctor
      }
      const isAnyParam = (params.date || params.status || params.keyword || params.treatmentName || params.doctorName)
      if (!isAnyParam) {
        this.$swal('請輸入任一資料', '', 'warning')
        return
      }
      apiGetReservations(params).then(data => {
        console.log(data)
        if (data.content) {
          this.searchResults = data.content
          this.onCloseReservationSearchBox()
        } else {
          if (data.error) {
            console.log(data.error.message)
          }
        }
      })
    },
    serviceStaffColorClass (jobName) {
      return serviceStaffColorClass(jobName)
    },
    ...mapActions(
      'modalUI', ['showPop', 'closePop']
    )
  },
  mounted () {
    console.log('mounted nscreen')
  }
}
</script>
