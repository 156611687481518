<template>
  <div class="search-client-data__item">
    <div class="search-client-data__avatar">
        <img v-bind:src="photo" alt="">
    </div>
    <h4 class="search-client-data__name">{{patient.fullName}}</h4>
    <p class="search-client-data__info">{{birthdayTW}}</p>
  </div>
</template>

<script>
import { photoUrl } from '@/api/v1/reservations'
import { formatTWBirthDay } from '../utils/utils'

export default {
  name: 'CardClientSearchResultItem',
  props: ['patient'],
  data () {
    return {
    }
  },
  computed: {
    photo () {
      if (this.patient.photo) {
        return photoUrl('patients', this.patient.patientId)
      } else {
        // use default profile head icon
        return (this.patient.gender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
      }
    },
    birthdayTW () {
      return formatTWBirthDay(this.patient.birthDate)
    }
  }
}
</script>
<style scoped>
.search-client-data__item {
  cursor: pointer;
}
</style>
