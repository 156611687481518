<template>
  <!--請選擇約診時段-->
  <div class="reservation-time" :class="{ 'is-active': isActive }">
    <div class="reservation-time__header">
      <h3>請選擇約診時段</h3>
    </div>
    <div class="reservation-time__wrap">
      <div class="reservation-time__date">
        <button class="reservation-time__btn-prev" @click="onPrev"></button>
        <datepicker class="reservation-time__datepicker" inputFormat="yyyy.MM.dd" v-model="startDate" />
        <span class="reservation-time__current-date">{{ dateRange }}</span>
        <button class="reservation-time__btn-next" @click="onNext"></button>
      </div>
      <div class="reservation-time__day-wrap">
        <div class="reservation-time__days" v-for="day in days" :key="day">
          <p class="reservation-time__week">{{ weekdaysC[day.date.getDay()] }}</p>
          <p class="reservation-time__day">{{ day.date.getDate() }}</p>
          <div class="reservation-time__times">
            <!--
              共同：
              無特殊class：該時間為有效，且該醫師尚未被預約。
              is-active: 目前選擇時間，藍色外框。
              醫療：
              is-disable: 項目為醫療，且所選時間該醫師已被預約，且沒有硬約權限。灰色，不可選。
              is-fake-disable: 項目為醫療，且所選時間該醫師已被預約, 但有硬約權限仍可硬約，灰色，可選。
              美療：
              is-disable-red: 項目為美療，且所選時間該美容師已被預約，無論有沒有硬約權限都不可選。橘色，不可選。
              is-fake-disable: 項目為美療，且所選時間可預約人數已達上限, 但有硬約權限仍可硬約，灰色，可選。

              isOccupied(day.date, time) && !canForceSelect
              isOccupied(day.date, time) && canForceSelect
            -->
            <div
              class="reservation-time__period"
              :class="{
                'is-active': (selectedDay.getTime() == day.date.getTime() && selectedTime === time),
                'is-disable': isDisable(day.date, time),
                'is-disable-red': isDisableRed(day.date, time),
                'is-fake-disable': isFakeDisable(day.date, time)
              }"
              v-for="time in day.times"
              :key="time"
              @click="selectTime(day.date, time)"
            >
              {{ time }}
            </div>
            <!-- is-active is-disable-->
          </div>
        </div>
      </div>
    </div>
    <button class="reservation-time__btn-confirm" @click="onConfirm">
      確定
    </button>
  </div>
  <!--請選擇約診時段-->
</template>
<script>
import Datepicker from 'vue3-datepicker'
import { formatDate, formatDateTime } from '../utils/utils'
import { apiGetScheduleTime } from '../api/v1/reservations'

export default {
  name: 'ReservationTime',
  props: ['isActive', 'treatmentId', 'staff', 'isMedical'],
  emits: ['confirm'],
  watch: {
    searchParams () {
      this.fetchData()
    },
    treatmentId () {
      if (!this.treatmentId) {
        this.reset()
      }
    }
  },
  data () {
    return {
      startDate: new Date(),
      weekdaysC: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
      selectedDay: new Date(),
      selectedTime: '',
      schedules: [], // 指定醫師已經被預約的時間段 *不限療程*
      schedulesAll: [], // 該療程（醫療）或美療（所有）已經被預約的所有時間段，不限哪位醫師或美容師
      workingHours: [],
      timeUntilDoctor: 0, // minutes from checkin to doctor start
      doctorMinutesNeeded: 0, // minutes needed for doctor treatment
      maxConcurrentReservations: [],
      timeSlots: {} // 約診時段
      // TODO: DEAL WITH SELECT SUNDAY!
    }
  },
  components: { Datepicker },
  computed: {
    // 開放控台與ADMIN可以硬選
    canForceSelect () {
      return (this.$store.state.auth.user.roles[0].includes('ROLE_ADMIN') || this.$store.state.auth.user.roles[0].includes('ROLE_FRONTDESK'))
    },
    // 名字有「不指定」就可硬約
    isNoSpecifyTreatmentStaff () {
      return this.staff.fullName?.includes('不指定')
    },
    searchParams () {
      return [this.treatmentId, this, this.staff.id, this.startDate]
    },
    endDate () {
      return this.calcWorkingDays(this.startDate, 6)
    },
    dateRange () {
      // return (`${formatDate(this.startDate)}~${formatDate(this.endDate)}`).replaceAll('-', '.')
      // return `${formatDate(this.startDate)}~${this.endDate.getDate()}`.replaceAll('-', '.')
      return `${formatDate(this.endDate)}`.replaceAll('-', '.')
    },
    allDatesInRange () {
      const days = []
      const toDate = new Date(this.startDate)
      while (toDate <= this.endDate) {
        const date = new Date(toDate)
        days.push(date)
        toDate.setDate(toDate.getDate() + 1)
      }
      return days
    },
    days () {
      const days = []
      var count = 0
      const toDate = new Date(this.startDate)
      while (count < 6) {
        if (toDate.getDay() !== 0) {
          const date = new Date(toDate)
          const day = {
            date: date,
            times: this.getTimeSlots(date)
          }
          // Skip sunday
          days.push(day)
          count++
        }
        toDate.setDate(toDate.getDate() + 1)
      }
      return days
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    getTimeSlots (date) {
      return this.isMedical ? (date.getDay() === 6 ? this.timeSlots.medicalSaturday : this.timeSlots.medicalWeekday) : (date.getDay() === 6 ? this.timeSlots.beautifySaturday : this.timeSlots.beautifyWeekday)
    },
    reset () {
      this.startDate = new Date()
      this.selectedDay = new Date()
      this.selectedTime = ''
    },
    fetchData () {
      this.selectedTime = ''
      if (!(this.treatmentId && this.staff.id)) {
        return
      }
      const params = {
        staff_id: this.staff.id,
        start: formatDate(this.startDate, '-'),
        end: formatDate(this.endDate, '-')
      }
      // 預約時間表上，所選時段的「醫師處置開始~結束」時間 若與已被佔用的醫師處置時間區段重疊，則不可預約
      apiGetScheduleTime(this.treatmentId, params).then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.timeSlots = data.timeSlots
          this.workingHours = data.workingHours
          this.doctorMinutesNeeded = data.doctorMinutesNeeded
          this.timeUntilDoctor = data.timeUntilDoctor
          this.maxConcurrentReservations = data.maxConcurrentReservations
          this.schedules = data.schedules
          this.schedulesAll = data.schedulesAll
        }
      })
    },
    addMinutes (date, minutes) {
      return new Date(date.getTime() + minutes * 60000)
    },
    calcWorkingDays (fromDate, days) {
      var count = 0
      const toDate = new Date(fromDate)
      while (count < days) {
        toDate.setDate(toDate.getDate() + 1)
        if (toDate.getDay() !== 0) {
          // Skip sunday
          count++
        }
      }
      return toDate
    },
    calcWorkingDaysBack (fromDate, days) {
      var count = 0
      const toDate = new Date(fromDate)
      while (count < days) {
        toDate.setDate(toDate.getDate() - 1)
        if (toDate.getDay() !== 0) {
          // Skip sunday
          count++
        }
      }
      return toDate
    },
    onNext () {
      const newStart = this.calcWorkingDays(this.startDate, 6)
      this.startDate = newStart
    },
    onPrev () {
      const newStart = this.calcWorkingDaysBack(this.startDate, 6)
      this.startDate = newStart
    },
    selectTime (day, time) {
      this.selectedDay = day
      this.selectedTime = time
    },
    onConfirm () {
      if (this.selectedTime) {
        const dateTime = formatDateTime(this.selectedDay, this.selectedTime)
        console.log(dateTime)
        this.$emit('confirm', dateTime)
      }
    },
    isExpired (day, time) {
      const dd = formatDateTime(day, time)
      // 排除已超過現在時間的時段
      const testDay = this.iOSNewDate(dd)
      if (testDay < new Date()) {
        return true
      }
      // 排除不在工作時間的時段
      if (!this.isInWorkingHours(testDay)) {
        return true
      }
    },
    dateInRange (range, dateStart, dateEnd) {
      const rangeStart = new Date(range.start)
      const rangeEnd = new Date(range.end)
      return this.dateRangeOverlaps(rangeStart, rangeEnd, dateStart, dateEnd)
    },
    dateRangeOverlaps (aStart, aEnd, bStart, bEnd) {
      if (aStart <= bStart && bStart <= aEnd) return true // b starts in a
      if (aStart <= bEnd && bEnd <= aEnd) return true // b ends in a
      if (bStart < aStart && aEnd < bEnd) return true // a in b
      return false
    },
    isOccupied (day, time) {
      const dd = formatDateTime(day, time, '/')
      const dDate = new Date(dd)
      const doctorStart = this.addMinutes(dDate, this.timeUntilDoctor)
      const doctorEnd = this.addMinutes(doctorStart, this.doctorMinutesNeeded)
      // 如果 (time + minutes to doctor) ~ (time + minutes to doctor + time needed)
      return this.schedules.find(s => this.dateInRange(s, doctorStart, doctorEnd)) !== undefined
    },
    isOccupiedAll (day, time) {
      const dd = formatDateTime(day, time, '/')
      const dDate = new Date(dd)
      const doctorStart = this.addMinutes(dDate, this.timeUntilDoctor)
      const doctorEnd = this.addMinutes(doctorStart, this.doctorMinutesNeeded)
      // 如果 (time + minutes to doctor) ~ (time + minutes to doctor + time needed)
      return this.schedulesAll.find(s => this.dateInRange(s, doctorStart, doctorEnd)) !== undefined
    },
    // 該時段是否尚未達到可預約人數上限
    isStillBookable (day, time) {
      const dd = formatDateTime(day, time, '/')
      const dDate = new Date(dd)
      const doctorStart = this.addMinutes(dDate, this.timeUntilDoctor)
      const doctorEnd = this.addMinutes(doctorStart, this.doctorMinutesNeeded)
      const bookedCount = this.schedulesAll.filter(s => this.dateInRange(s, doctorStart, doctorEnd)).length
      const index = this.allDatesInRange.findIndex(p => formatDate(p) === formatDate(day))
      // console.log(`isStillBookable day = ${day}, dd = ${dd}, index = ${index}, bookedCount = ${bookedCount} max = ${this.maxConcurrentReservations[index]}`)
      if (index >= 0 && index < this.maxConcurrentReservations.length) {
        return bookedCount < this.maxConcurrentReservations[index]
      }
      return false
    },
    /*
      is-active: 目前選擇時間，藍色外框。
      醫療：
      is-disable: 項目為醫療，且所選時間該醫師已被預約，且沒有硬約權限。灰色，不可選。
      is-fake-disable: 項目為醫療，且所選時間該醫師已被預約, 但有硬約權限仍可硬約，灰色，可選。
      美療：
      is-disable: 項目為美療，且所選時間可預約人數已達上限，或非工作時間 或 現在時間已超過該時間，且沒有硬約權限。灰色，不可選。
      is-disable-red: 項目為美療，且所選時間該美容師已被預約，無論有沒有硬約權限都不可選。橘色，不可選。
      is-fake-disable: 項目為美療，且所選時間可預約人數已達上限, 但有硬約權限仍可硬約，灰色，可選。

      同時段阻擋邏輯：
      - 醫生相同、無論療程相同或療程不同 => 檔
      - 醫生不同、療程相同，且同時段同療程預約人數達該療程上限 => 檔
    */
    isDisable (day, time) {
      if (this.isMedical) {
        return ((
          this.isOccupied(day, time) || // same doctor
          // this.isOccupiedAll(day, time) || // any doctor
          this.isExpired(day, time) ||
          !this.isStillBookable(day, time)) &&
          !this.canForceSelect)
      } else {
        return ((this.isExpired(day, time) || !this.isStillBookable(day, time)) && !this.canForceSelect)
      }
    },
    isDisableRed (day, time) {
      if (!this.isMedical && !this.isNoSpecifyTreatmentStaff) {
        return (this.isOccupied(day, time) && this.isStillBookable(day, time))
      }
      return false
    },
    isFakeDisable (day, time) {
      if (this.isMedical) {
        return ((
          this.isOccupied(day, time) || // same doctor
          // this.isOccupiedAll(day, time) || // any doctor
          this.isExpired(day, time) ||
          !this.isStillBookable(day, time)) &&
          this.canForceSelect)
      } else {
        return ((this.isExpired(day, time) || !this.isStillBookable(day, time)) && this.canForceSelect)
      }
    },
    isInWorkingHours (date) {
      for (let index = 0; index < this.workingHours.length; index++) {
        const wh = this.workingHours[index]
        const startDate = this.iOSNewDate(wh.start)
        const endDate = this.iOSNewDate(wh.end)
        if (date >= startDate && date <= endDate) {
          return true
        }
      }
      return false
    },
    iOSNewDate (date) {
      return new Date(date.replace(/-/g, '/'))
    }
  }
}
</script>
<style scoped lang='scss'>
.reservation-time {
  &__period {
    cursor: pointer;
  }
}
.is-fake-disable {
  color: var(--color-period-disable);
}
.is-disable-red {
  color: #ff6424;
  pointer-events: none;
}
</style>
